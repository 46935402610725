@import url("https://fonts.googleapis.com/css2?family=Questrial&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Raleway:wght@100;200;300;400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700;800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Oswald&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&display=swap");
// @font-face {
//   font-family: "Gotham Rounded";
//   src: url("../Fonts/GothamRoundedMedium.ttf") format("truetype");
// }
// @font-face {
//   font-family: "Gotham Rounded Light";
//   src: url("../Fonts/GothamRoundedLight.ttf") format("truetype");
// }

$body-bg: #fff;
$body-color: #123953;

$theme-colors: (
  "green": #33a563,
  "site-primary": #03223f,
  "site-secondary": #123953,
  "yellow": #ff7900,
  "main-container": #eff3f9,
  "site-table-color": #d6d5d3,
  "txt-footer-color": #a7a6a6,
  "site-yellow": #ffde00,
  "site-horizontalBar-color": #48cae4,
  "paragraph-color": #292929,
  "promotion-text": #ffffff,
  "site-gray": #e3e3e3,
  "site-pink": #ffe4cc,
);

// 48cae4
@import "node_modules/bootstrap/scss/bootstrap";

body {
  overflow-x: hidden;
  color: #123953;
}

.popinsFont {
  font-family: "Poppins", sans-serif !important;
}

html {
  font-size: 18px;

  @media (max-width: 1799.98px) {
    font-size: 17px;
  }

  @media (max-width: 1399.98px) {
    font-size: 16px;
  }

  @media (max-width: 1199.98px) {
    font-size: 13px;
  }

  @media (max-width: 991.98px) {
    font-size: 12px;
  }

  @media (max-width: 567.98px) {
    font-size: 11px;
  }
}

$weights: 100 200 300 400 500 600 700 800;

@each $weight in $weights {
  .fw--#{$weight} {
    font-weight: $weight;
  }
}

@for $size from 12 through 96 {
  .fs--#{$size} {
    font-size: $size/16 + rem;
  }
}

.cursor-pointer {
  cursor: pointer;
}

.topBar {
  @media only screen and (min-width: 1200px) {
    display: flex !important;
  }

  // @media (max-width: 768.98px) {
  // 	padding: 1px;
  // }
  &_paragraph {
    font-size: 150%;
    letter-spacing: -1px;
    line-height: 1rem;
    padding-top: 1.1rem;

    @media (max-width: 767.98px) {
      padding-top: 0.5rem;
      font-size: 130%;
      line-height: 1.5rem;
    }

    @media (max-width: 425.98px) {
      // font-size: 150%;
      // margin-left: 51px !important;
      margin-left: 96px !important;
      font-size: 111%;
      line-height: 1.5rem;
      letter-spacing: 0px;
      // margin: 0rem 1rem 0rem 1rem;
    }
  }

  &_logo {
    width: 100%;
    height: auto;

    @media (max-width: 428.98px) {
      // height: 60px;
      // width: 120px;
      height: 47px !important;
      width: 154px !important;
    }

    @media (max-width: 767.98px) {
      padding-top: 0.5rem;
      font-size: 130%;
      line-height: 1.5rem;
    }

    // @media (max-width: 425.98px) {
    // 	// height: 38px;
    // 	// width: 107px;
    // 	// height: 58px;
    // 	height: 60px !important;
    // 	width: 120px !important;
    // }
  }
}

.MainContent1 {
  &_img-promotion {
    position: absolute;
    right: 20%;
    top: -10%;
    max-height: 40%;
    z-index: 999;
  }
}

// Right Image
.right_img {
  height: 50%;
  // margin-top: 45px;
  margin-top: -40px;

  @media (max-width: 1199.98px) {
    margin-top: 0;
    margin-left: -20px;
  }

  @media only screen and (max-width: 992.98px) and (min-width: 767.98px) {
    margin-top: 80px;
  }

  &_img {
    margin-bottom: -65px;
    // margin-left: -188px;
    // Tablet
    // @media only screen and (max-width: 768px) and (min-width: 481px) {
    // 	/* iPads, Tablets */
    // margin-top: 6%;
    // margin-left: -166px;
    // width: 162%;
    // height: 212%;
    // margin-left: -100px;
    // }
    // @media only screen and (max-width: 992.98px) and (min-width: 768.98px) {
    //   width: 180% !important;
    //   overflow-x: hidden;

    // margin-left: -100px;
    // }
    @media (min-width: 1799.98px) {
      width: 162%;
    }
  }
}

.hero-section {
  overflow-x: clip;

  &_logo-adj {
    justify-content: center;

    @media (max-width: 767.98px) {
      justify-content: left !important;
    }
  }

  &_form_container {
    height: 685px;

    @media (max-width: 1198.98px) {
      height: 580px;
    }

    @media (max-width: 1025.98px) {
      height: 560px;
    }

    @media (max-width: 998.98px) {
      height: 520px;
    }

    @media (max-width: 991.98px) {
      height: 480px;
    }

    @media (max-width: 767.98px) {
      height: 723px;
    }

    @media (max-width: 425.98px) {
      height: 761px;
    }

    @media (max-width: 366.98px) {
      height: 701px;
    }

    @media (max-width: 320.98px) {
      // height: 704px;
      // height: 393px;
      height: 754px;
    }
  }

  &_Form-col {
    position: relative;
    z-index: 2;

    @media (max-width: 767.98px) {
      width: 500px;
    }

    @media only screen and (max-width: 767.98px) and (min-width: 481px) {
      /* iPads, Tablets */
    }

    &_paragraph {
      color: paragraph-color;
      font-size: 150%;
      text-align: justify;

      @media (max-width: 767.98px) {
        font-size: 135%;
      }

      @media (max-width: 425.98px) {
        font-size: 150%;
      }

      @media (max-width: 375.98px) {
        font-size: 140%;
      }

      @media (max-width: 343.98px) {
        font-size: 120%;
      }
    }

    &_Check-text {
      letter-spacing: -1px;
      line-height: 61px;
      color: #ff7900;
      font-weight: 700;
      font-size: 220%;
      font-family: "Open Sans";
      text-align: center;
      border: #03223f;

      @media (max-width: 767.98px) {
        font-size: 220%;
      }

      @media (max-width: 425.98px) {
        font-size: 200%;
      }

      @media (max-width: 375.98px) {
        font-size: 150%;
      }

      @media (max-width: 320.98px) {
        font-size: 190%;
      }
    }
  }

  &_Input {
    font-size: 300%;
    height: 120%;
    border-radius: 7px;
    background-color: #f8f8f8;
    letter-spacing: 1rem;
    text-align: center;
    border: 0.5px solid #a0a0a0;
  }

  &_Input::placeholder {
    height: 77px;
    letter-spacing: 20px;
    color: #9f9f9f;
    font-weight: 400;
    letter-spacing: 1rem;
    font-family: "Open Sans";
    text-align: center;
  }

  &_Poster-col {
    &_badge {
      position: absolute;
      z-index: 22;
      left: -60px;
      top: -5px;
    }

    &_mobile-div {
      position: relative;

      margin-bottom: -133px;
      // margin-left: 134px;
      margin-left: 146px;
      z-index: 3;

      &_person {
        position: absolute;
        z-index: 22;
        right: 0;
        bottom: 9px;
      }
    }

    &_MagazinePoster {
      left: -50px;
      top: -2%;
      position: absolute;
      // width: 100%;
      width: 96%;
      height: auto;

      &_Col2 {
        left: -50px;
        top: -2%;
        position: absolute;
        // width: 100%;
        width: 96%;
        height: auto;

        @media only screen and (max-width: 767.98px) and (min-width: 481px) {
          left: -50px;
          top: -2%;
          position: absolute;
          width: 108%;
          height: auto;
        }

        @media (min-width: 1440.98px) {
          left: -50px;
          top: -2%;
          position: absolute;
          width: 102%;
          height: auto;
        }
      }

      @media only screen and (max-width: 767.98px) and (min-width: 481px) {
        left: -50px;
        top: -2%;
        position: absolute;
        width: 108%;
        height: auto;
      }

      @media (min-width: 1440.98px) {
        left: -50px;
        top: -2%;
        position: absolute;
        width: 102%;
        height: auto;
      }

      // @media (max-width: 1440.98px) {
      // 	width: 103%;
      // }
      // @media (max-width: 1024.98px) {
      // 	width: 100%;
      // 	top: -3%;
      // }
      // @media (max-width: 991.98px) {
      // 	width: 110%;
      // }
      @media (max-width: 587.98px) {
        // width: 108%;
        // width: 58%;
        width: 56%;
      }

      // @media (max-width: 767.98px) {
      // 	left: 0px;
      // 	top: 0%;
      // 	position: relative;
      // 	// width: 100%;
      // 	// width: 56%;
      // 	height: auto;
      // }
    }

    &_PersonPicture {
      position: relative;
      // width: 75%;
      width: 65%;
      right: -30%;
      // top: 24%;
      top: 26%;

      &_Col2 {
        position: absolute;
        z-index: 22;
        right: 0;
        bottom: 0;
        top: 0%;
        width: 84%;

        @media (max-width: 991.98px) {
          position: absolute;
          z-index: 22;
          right: 0;
          bottom: 0;
          top: 0%;
          width: 84%;
        }

        @media only screen and (max-width: 767.98px) and (min-width: 481px) {
          /* iPads, Tablets */
          // top: 44%;
          top: 36%;
        }
      }

      // laptop
      // @media only screen and (max-width: 768px) and (min-width: 481px) {
      // 	/* iPads, Tablets */
      // 	// top: 44%;
      // 	top: 36%;
      // }

      // tblt
      @media only screen and (max-width: 767.98px) and (min-width: 481px) {
        /* iPads, Tablets */
        // top: 44%;
        top: 36%;
      }

      @media (min-width: 1440.98px) {
        position: relative;
        width: 72%;
        right: -30%;
        top: 26%;
      }

      // @media (max-width: 1440.98px) {
      // 	position: relative;
      // 	width: 65%;
      // 	right: -30%;
      // 	top: 26%;
      // }
    }

    // @media (max-width: 1440.98px) {
    // 	right: -35%;
    // 	top: 21%;
    // }
    // @media (max-width: 1024.98px) {
    // 	top: 21%;
    // }
    // @media (max-width: 991.98px) {
    // 	top: 24%;
    // }
    @media (max-width: 587.98px) {
      right: -278px;
      top: 24%;
      position: absolute;
      width: 41%;
      height: auto;
    }

    @media (max-width: 425.98px) {
      right: 0%;
      top: 24%;
      position: absolute;
      width: 75%;
      height: auto;
    }
  }
}

.registerationForm {
  &_tip {
    z-index: 999;
    margin-top: -20px;
    justify-content: center;
    height: 35px;

    fill: #33a563;
  }

  &_para1 {
    letter-spacing: -1px;
    color: #ffffff;
    font-weight: 600;
    font-family: "Open Sans";
    text-align: center;

    @media (max-width: 786.98px) {
      text-align: left !important;
      width: 50%;
      margin-left: 13px;
    }

    @media (max-width: 320.98px) {
      font-size: 14px;
      text-align: left !important;
      width: 50%;
    }
  }

  &_para2 {
    // font-size: 19px;
    letter-spacing: 0px;
    color: #ffffff;
    font-weight: 700;
    font-family: "Open Sans";
    text-align: center;

    @media (max-width: 786.98px) {
      text-align: left !important;
      width: 50%;
      margin-left: 13px;
    }
  }

  &_inputflieds {
    background-color: #f8f8f8;
    font-size: small;
    color: #000000;
    font-weight: bold;
  }

  &_inputflieds::placeholder {
    color: #808080;
    font-size: small;
    font-weight: 200;
  }

  &_inputflieds:hover {
    background-color: #ffffff;
    font-weight: light !important;
    border-color: #03223f;
  }

  &_inputflieds::-webkit-outer-spin-button,
  &_inputflieds::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }

  &_btn {
    font-size: 150%;
    color: #ffffff;
    margin-top: 5%;

    @media (max-width: 1440.98px) {
      margin-top: 10%;
      margin-bottom: 2%;
    }

    @media (max-width: 1024.98px) {
      margin-bottom: 2%;
    }

    // @media (max-width: 768.98px) {
    // }
    // @media (max-width: 425.98px) {
    // }
    &_img {
      width: 16%;
      height: auto;
    }
  }
}

.btn-postcode {
  font-size: 125% !important;
  border: none;
  //   @media (max-width: 567.98px) {
  //     font-size: 18px !important;
  //   }

  .button-image {
    width: 50px;

    @media (max-width: 767.98px) {
      width: 40px;
    }

    @media (max-width: 567.98px) {
      width: 30px;
    }
  }
}

.btn-logo {
  width: 58px;
  height: 58px;
  border-radius: 29px;
  background-color: #ff7900;
}

.promotion-bar {
  position: relative;
  z-index: 3;
  font-size: 180%;
  align-self: center;

  @media (max-width: 375.98px) {
    font-size: 140%;
  }
}

.card {
  @media only screen and (max-width: 767.98px) {
    background-color: transparent;
  }
}

.was-validated .form-control:invalid:focus,
.form-control.is-invalid:focus {
  //   border: none !important;
  border-color: #ced4da;
  background-image: none !important;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 254, 0.25);
  //   box-shadow: none;
}

.form-control:required:is-invalid {
  //   border: none !important;
  background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 width=%2712%27 height=%2712%27 fill=%27none%27 stroke=%27%23dc3545%27 viewBox=%270 0 12 12%27%3e%3ccircle cx=%276%27 cy=%276%27 r=%274.5%27/%3e%3cpath stroke-linejoin=%27round%27 d=%27M5.8 3.6h.4L6 6.5z%27/%3e%3ccircle cx=%276%27 cy=%278.2%27 r=%27.6%27 fill=%27%23dc3545%27 stroke=%27none%27/%3e%3c/svg%3e") !important;
  background-repeat: no-repeat;
  box-shadow: #808080 !important;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}

//2nd max_magazine

.hero-contents {
  height: 45em;

  @media (min-width: 1475px) {
    height: 44em;
  }

  @media (min-width: 1400px) {
    height: 50em;
  }

  @media (max-width: 1399.98px) {
    height: 50em;
  }

  @media (max-width: 1199.98px) {
    height: 56em;
  }

  @media (max-width: 991.98px) {
    height: 55em;
  }

  @media screen and (max-width: 767.98px) {
    height: auto;
  }

  .overflow-visible {
    overflow: visible !important;
  }

  &__bg {
    position: relative;
    background-color: #eff3f9;
    color: black;
    display: flex;
    flex-direction: column;
    overflow-x: hidden;
    // border-top-right-radius: 10px;
    border-bottom-right-radius: 230px;
    z-index: 1;

    @media screen and (max-width: 767.98px) {
      padding: 30px 0px 0 !important;
      border-radius: 0% !important;
    }

    @media screen and (max-width: 1024.98px) {
      .column {
        width: 30% !important;
      }
    }

    // .badgeHerol {
    //   position: absolute;
    //   // top: 0.1px;
    //   right: 20px;
    //   bottom: 30px !important;
    //   // width: 300px;
    //   @media (min-width: 1750px) {
    //     width: 450px;
    //   }
    //   @media (max-width: 1749.98px) {
    //     width: 400px;
    //   }
    //   @media (max-width: 1549.98px) {
    //     width: 350px;
    //     right: 10px;
    //   }
    //   @media (max-width: 1199.98px) {
    //     width: 270px;
    //     right: 10px;
    //   }
    //   @media (max-width: 770.98px) {
    //     width: 200px;
    //     // right: -100px;
    //     // bottom: 40px;
    //   }
    //   @media (max-width: 767.98px) {
    //     // right: 60px !important;
    //     position: relative;
    //     width: 120px;
    //     // right: -100px;
    //     bottom: 60px;
    //   }
    //   @media (max-width: 449.98px) {
    //     position: relative;
    //     bottom: 0;
    //     right: 0px !important;
    //     // width: 170px;
    //     height: 180px;
    //   }
    // }

    .bottom-bar {
      position: relative;
      width: 100%;
      background-color: #ff7900;

      .badgeBol {
        position: absolute;
        top: -70px;
        width: 80%;
        right: 0;

        @media (min-width: 2200px) {
          top: -100px;
          width: 65%;
        }

        @media (max-width: 2199.98px) {
          top: -70px;
          width: 65%;
        }

        @media (max-width: 1599.98px) {
          top: -50px;
          width: 70%;
        }

        @media (max-width: 991.98px) {
          top: -65px;
          width: 70%;
        }

        @media (max-width: 767.98px) {
          top: auto;
          position: relative;
          width: 150px;
          margin-top: -60px;
        }

        @media (max-width: 499.98px) {
          height: 75px;
          width: 120px;
          margin-top: -50px;
        }
      }
    }
  }

  .badgeHero {
    position: absolute;
    // top: 0.1px;
    z-index: 10;
    left: -22rem;
    bottom: 50px;

    // width: 300px;
    @media (min-width: 1750px) {
      width: 450px;
      left: -25rem;
      bottom: 60px;
    }

    @media (max-width: 1749.98px) {
      width: 400px;
      left: -24rem;
    }

    @media (max-width: 1549.98px) {
      width: 350px;
      // right: 10px;
      left: -21rem;
    }

    @media (max-width: 1299.98px) {
      width: 270px;
      left: -18rem;
    }

    @media (max-width: 1199.98px) {
      width: 270px;
      right: 10px;
      left: -21rem;
    }

    @media (max-width: 991.98px) {
      width: 270px;
      right: 70px;
      left: auto;
    }

    @media (max-width: 770.98px) {
      width: 200px;
      // right: -100px;
      // bottom: 40px;
    }

    @media (max-width: 767.98px) {
      // right: 60px !important;
      position: relative;
      width: 120px;
      right: -5px;
      bottom: 60px;
    }

    @media (max-width: 449.98px) {
      position: relative;
      bottom: 0;
      // right: 0px !important;
      // width: 170px;
      height: 180px;
    }
  }

  .badgeBig {
    width: 100%;
    // height: 90%;
    align-self: center !important;
    margin-left: -100px;
    z-index: 9999;

    @media (min-width: 1700px) {
      width: 85%;
      margin-left: -180px;
    }

    @media (max-width: 767.98px) {
      margin: 30px auto 0;
      display: flex;
      width: 70%;
      height: auto;
    }
  }

  .arrow-up {
    top: -50px;
    width: 50px;
    right: 150px;

    @media (max-width: 1299.98px) {
      top: -30px;
      right: 80px;
    }
  }

  .text_right {
    float: right;

    @media (max-width: 991.98px) {
      float: left;
    }
  }

  &__title {
    font-size: 40px;
    font-weight: 700;
    line-height: 1.1;

    @media (max-width: 991.98px) {
      font-size: 32px;
    }

    @media (max-width: 576.98px) {
      font-size: 35px;
    }
  }

  &__titleY {
    // font-size: 40px;
    font-weight: 700;
    line-height: 1.1;

    @media (min-width: 1320px) and (max-width: 1509.98px) {
      font-size: 35px;
    }

    @media (min-width: 1200px) and (max-width: 1319.98px) {
      font-size: 32px;
    }

    @media (min-width: 992px) and (max-width: 1199.98px) {
      font-size: 30px;
    }

    @media (max-width: 991.98px) {
      font-size: 32px;
    }

    @media (min-width: 502px) and (max-width: 576.98px) {
      font-size: 30px;
    }
  }

  &__text {
    font-size: 22px;
    line-height: 22px;
    background-color: #c8d8e3;
    border-top-right-radius: 16px;
    border-bottom-right-radius: 16px;

    @media (max-width: 991.98px) {
      font-size: 18px;
    }

    @media (max-width: 767.98px) {
      border-radius: 0 !important;
      // margin: 0 !important;
    }
  }

  &__textdiv {
    font-size: 22px;
    line-height: 22px;
    background-color: #c8d8e3;

    // width: 90%;
    @media (max-width: 991.98px) {
      font-size: 18px;
    }
  }

  &__smtext {
    font-size: 17px;

    @media screen and (max-width: 576px) {
      font-size: 15px;
    }
  }

  &__xstext {
    font-size: 12px;
    font-style: italic;

    @media screen and (max-width: 576px) {
      font-size: 10px;
    }
  }

  &__smtext2 {
    @media screen and (max-width: 576px) {
      // text-align: center;
    }
  }

  &__optext {
    font-size: 14px;
    font-weight: 700;
  }

  &__btn {
    width: 220px !important;
    height: 45px;
    border-radius: 10px;
    // font-weight: 700;
    font-size: 18px;
    // background-color: ;
    border: none;
    color: white;

    @media (max-width: 1024px) {
      width: 300px !important;
      height: 49px;
      font-size: 22px;
    }

    @media (max-width: 348.98px) {
      width: 100% !important;
      height: 39px;
      font-size: 18px;
      padding: 16px !important;
      margin: auto;
    }
  }

  &__img {
    background-image: url(../img/heroImg1.png);

    background-position: right;
    background-size: 100% 100%;
    background-repeat: no-repeat;

    @media (max-width: 991.99px) {
      background-image: url();
      background-color: #ebeaeb;
    }

    @media (min-width: 2200px) {
      background-size: 45% 100%;
    }

    @media (min-width: 2000px) and (max-width: 2199.98px) {
      background-size: 52% 100%;
    }

    @media (min-width: 1600px) and (max-width: 1999.98px) {
      background-size: 60% 100%;
    }

    @media (min-width: 1400px) and (max-width: 1599.98px) {
      background-size: 70% 100%;
    }

    @media (min-width: 1100px) and (max-width: 1399.98px) {
      background-size: 80% 100%;
    }

    @media (min-width: 991.98px) and (max-width: 1099.98px) {
      background-size: 85% 100%;
    }

    // img {
    //   margin-left: -14px;
    //   width: 102% !important;
    //   height: 100% !important;
    //   object-fit: cover;

    //   @media screen and (max-width: 575px) {
    //     margin-left: 0% !important;
    //     width: 100% !important;
    //     height: 100% !important;
    //   }
    // }
  }

  &__card {
    background-color: #ebebeb;
  }
}

.bg-footer2 {
  background-color: #0070ba;
  color: #fff;
  padding-top: 1%;
  padding-bottom: 1%;

  p {
    font-size: 14px;
  }
}

.card-styling {
  border-radius: 1em !important;
  // margin-bottom: 5em;
  background-color: #fff !important;
}

.form-style {
  width: 27em;
  border-radius: 1em !important;
  background-color: #fff !important;
  color: #123953 !important;

  // height: 75%;
  @media (max-width: 991.98px) {
    margin-left: 6rem;
  }

  @media (max-width: 767.98px) {
    margin-left: 0;
    width: 30em;
    border-radius: 1em !important;
    background-color: #fff !important;
    position: relative !important;
    // height: 100%;
    align-self: center;
  }

  @media (max-width: 767.98px) {
    width: 25em;
  }
}

.number-input::-webkit-outer-spin-button,
.number-input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

.form-group {
  margin-bottom: 10px;
}

.form-button {
  border-radius: 13px;
}

.custom-input {
  color: #03223f;
  // font-weight: 600;
  font-size: small;
}

.form-control {
  height: calc(1.5em + 0.75rem + 8px);
  padding: 0.375rem 0.9rem;
}

.custom-input::placeholder {
  color: #000;
  font-size: small;
  // font-weight: 600;
}

.header {
  span {
    @media (max-width: 412.98px) {
      font-size: smaller;
    }

    @media (max-width: 312.98px) {
      font-size: xx-small;
    }
  }
}

//max#2

.badge-sm {
  @media (min-width: 992px) and (max-width: 1299.98px) {
    margin-right: -3rem !important;
  }

  @media (min-width: 768px) and (max-width: 991.98px) {
    margin-right: -20rem !important;
    width: 300px;
  }

  @media (max-width: 767.98px) {
    width: 300px;
    margin-bottom: -3rem;
  }
}

// update ui

.navbar p,
.HeroLeftCol span {
  border: 1px solid #ff7900;
  padding: 8px;
  border-radius: 5px;
  color: #183b56;
  font-family: "DM Sans", sans-serif;
  font-weight: 400;
}

.HeroLeftCol h1 {
  font-size: 4rem;
  font-family: "DM Sans", sans-serif;
  color: #183b56;
  font-weight: 700;

  @media screen and (max-width: 1424.98px) {
    font-size: 3.5rem;
  }

  @media screen and (max-width: 1239.98px) {
    font-size: 3.3rem;
  }

  @media screen and (max-width: 430.98px) {
    font-size: 2.9rem;
  }
}

.HeroLeftCol {
  h2 {
    font-size: 2rem;
    color: #f97304;
    font-family: "Poppins", sans-serif;

    @media screen and (max-width: 1424.98px) {
      font-size: 1.6rem;
    }

    @media screen and (max-width: 430.98px) {
      font-size: 1.5rem;
    }
  }

  p {
    font-size: 22px;
    color: #5a7184;
    font-family: "Poppins", sans-serif;

    @media screen and (max-width: 1229.98px) {
      font-size: 20px;
    }

    @media screen and (max-width: 1030.98px) {
      font-size: 18px;
    }

    @media screen and (max-width: 500.98px) {
      font-size: 12px;
    }
  }
}

@media (max-width: 767.98px) {
  .mobile {
    margin-bottom: -100px;
    position: relative;
    z-index: 999;
  }
}

// @media screen and (max-width: 1424.98px) {
//   .HeroLeftCol h1 {
//     font-size: 3.5rem;
//   }
//   .HeroLeftCol h2 {
//     font-size: 1.6rem;
//   }
// }

.Container {
  max-width: 1500px;
  padding: 0 2.8rem;
  overflow: hidden;

  @media (min-width: 1500px) {
    margin: 0 auto;
  }

  @media (max-width: 450px) {
    padding: 0 20px;
  }
}

.HeroRightCol .desktop {
  margin-top: -95px;
  margin-right: -150px;
  // z-index: -10;
  // position: relative;
  min-width: -webkit-fill-available;
  max-width: -webkit-fill-available;
  width: -webkit-fill-available;

  @media (max-width: 991.98px) {
    margin-top: 0px;
  }
}

.HeroRightCol2 .desktop {
  margin-top: -95px;
  margin-right: -150px;
  // z-index: -10;
  // position: relative;
  min-width: -webkit-fill-available;
  max-width: -webkit-fill-available;
  width: -webkit-fill-available;
  position: relative;
  top: 38px;
  @media (max-width: 1292.5px) {
    margin-top: -58px;
  }
  @media (max-width: 1113.5px) {
    margin-top: -21px;
    right: 30px;
  }
  @media (max-width: 1017.5px) {
    margin-top: 13px;
  }
  @media (max-width: 991.5px) {
    margin-top: -95px;
    right: 0px;
  }

  @media (min-width: 375px) and(max-width:425px) {
    right: 34px;
    top: 85px;
  }
}
.HeroRightCol2 {
  .svg-img-div {
    svg {
      position: absolute;
      bottom: 81px;
      left: 35%;
      @media (max-width: 1292.98px) {
        width: 150px;
        height: 150px;
      }
      @media (max-width: 1146.98px) {
        width: 130px;
        height: 130px;
      }
      @media (max-width: 991.98px) {
        width: 190px;
        height: 190px;
      }
      @media (max-width: 800.98px) {
        width: 150px;
        height: 150px;
      }
      @media (max-width: 600.98px) {
        width: 130px;
        height: 130px;
      }
      @media (max-width: 424.98px) {
        bottom: 12px;
      }
    }
  }
}

.HeroRightCol {
  svg {
    position: absolute;
    bottom: 218px;
    left: 201px;

    @media (max-width: 1800.98px) {
      bottom: 123px;
    }

    @media (max-width: 1482.98px) {
      bottom: 176px;
    }

    @media (max-width: 1424.98px) {
      bottom: 98px;
    }

    @media (max-width: 991.98px) {
      bottom: 157px;
    }

    @media (max-width: 767.98px) {
      bottom: -79px;
      z-index: 99999;
      left: 457px;
    }

    @media (max-width: 567.98px) {
      bottom: -111px;
      left: 418px;
      width: 80px;
      height: 80px;
    }

    @media (max-width: 518.98px) {
      left: 388px;
    }

    @media (max-width: 488.98px) {
      left: 366px;
    }

    @media (max-width: 464.98px) {
      left: 345px;
      height: 70px;
      width: 70px;
    }

    @media (max-width: 435.98px) {
      left: 324px;
    }

    @media (max-width: 413.98px) {
      left: 300px;
      // bottom: -98px;
    }

    @media (max-width: 384.98px) {
      left: 289px;
    }
  }
}

.bottomSection {
  background-color: #fef7e7;
  padding: 1rem 0.3rem;
  // margin: 1.3rem 0px;
  border-radius: 10px;
  border: none;
  outline: none;

  @media (max-width: 992px) {
    top: 40px;
  }

  @media (min-width: 992px) and(max-width:425px) {
    top: 40px;
  }
}

.bottomSectionRow {
  @media (max-width: 768px) {
    display: block;
    align-items: center;
    text-align: center;
    width: 100%;
  }
}

.bottomBtn {
  text-align: end;
}

.bottomBtn .btn {
  pointer-events: none;
  opacity: 0.65;
}

.bottomBtn .btn,
.bottomSection .btn {
  background-color: #f97304;
  color: white;
  border: none;
  border-radius: 100px;
  padding: 16px 45px 16px 45px;
  margin-right: 13px;
  font-weight: 600;
  font-family: "Poppins", sans-serif;
}

.bottomLeftCol {
  display: flex;
  justify-content: center;

  // height: 67vh;
  // height: 510px;
  img {
    width: 60%;

    @media (max-width: 991.98px) {
      width: 82%;
    }
  }
}

.BottomRightCol {
  padding: 0 1rem;
}

.BottomRightCol p {
  color: #5a7184;
  font-family: "Poppins", sans-serif;
}

.BottomRightCol b {
  color: #5a7184;
  font-family: "Poppins", sans-serif;
  font-weight: 700;
}

.BottomRightCol h6 {
  font-weight: 700;
  font-family: "Poppins", sans-serif;

  color: #5a7184;
}

.BottomRightCol h5 {
  font-weight: 700;
  font-family: "Poppins", sans-serif;

  color: #262729;
}

.BottomRightCol2 {
  padding: 0 1rem;
}

.BottomRightCol2 p {
  color: #5a7184;
  font-family: "Poppins", sans-serif;
}

.BottomRightCol2 b {
  color: #262729;
  font-family: "Poppins", sans-serif;
  font-weight: 700;
}
.bottomText {
  b {
    color: #5a7184;
  }
}

.BottomRightCol2 h6 {
  font-weight: 700;
  font-family: "Poppins", sans-serif;

  color: #5a7184;
}

.BottomRightCol2 h5 {
  font-weight: 700;
  font-family: "Poppins", sans-serif;

  color: #262729;
}

@media (max-width: 767.98px) {
  .bottomSection {
    background-color: #fef7e7 !important;
    margin-top: 0;
    margin-bottom: 4rem;
    padding-top: 50px;
  }

  .BottomRightCol h6 {
    padding: 0 0 2.5rem 0;
  }

  .BottomRightCol2 h6 {
    padding: 0 0 2.5rem 0;
  }
}

@media screen and (min-width: 768px) and (max-width: 991.98px) {
  .bottomSection {
    margin-bottom: 1.5rem;
  }
}

.hero-button {
  border-radius: 30px;
  padding: 10px 18px;
  background-color: #f97304;
  border: none;
  color: white;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  width: -webkit-fill-available;
}

.hero-button2 {
  border-radius: 30px;
  padding: 10px 18px;
  background-color: #f97304;
  border: none;
  color: white;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  width: -webkit-fill-available;
  margin-right: 5px;
  z-index: 1;
}

.heroCard {
  border: none;
  padding: 5px;
  border-radius: 30px;
  width: 584px;

  @media screen and (max-width: 1200px) {
    width: 465px;
  }

  @media screen and (max-width: 500px) {
    width: 340px;
  }
}

.heroCard input {
  border-radius: 30px;
  border: 1px solid #e8ecfe;
  color: #969696;

  &::placeholder {
    color: #969696;
  }
}

.form-Badge {
  position: absolute;
  z-index: 9;
  margin-top: 21%;

  // top: 143px;
  @media (max-width: 1350.98px) {
    // top: 94px;
    right: -32px;
    width: 80px;
    height: 80px;
  }

  @media (max-width: 1194.98px) {
    // top: 94px;
    // right: -32px;
    width: 70px;
    height: 70px;
  }

  @media (max-width: 1144.98px) {
    margin-top: 23%;
  }

  @media (max-width: 991.98px) {
    // top: 151px;
    margin-top: 17%;
    right: 46px;
    width: 100px;
    height: 100px;
  }

  @media (max-width: 824.98px) {
    right: 9px;
  }
}

// update question section

.questionSection {
  background-color: #fef7e7 !important;
  padding: 2rem 4rem;
  border-radius: 15px;
  border: none;
  outline: none;

  @media (max-width: 450px) {
    padding: 2rem 20px;
  }
}

.questionLeftCol h6 {
  font-size: 0.9rem;
  font-weight: 600;
  font-family: "Poppins", sans-serif;

  color: #183b56;
}

.questionLeftCol span {
  font-size: 0.8rem;
  font-weight: 400;
  font-family: "Poppins", sans-serif;

  font-style: italic;
  color: #5a7184;
}

.questionLeftCol b {
  color: #183b56;
  font-size: 40px;
  font-weight: 700;
  font-family: "DM Sans", sans-serif;
}

.QuestionCards {
  border-radius: 100px;
  border: none;
}

.QuestionCards label {
  font-weight: 400;
  font-family: "Poppins", sans-serif;

  color: #000a38;
  line-height: 1;
}

.formSection {
  background-color: white !important;
  border-radius: 20px;
  margin: 2rem 0.5rem;
  padding: 1rem 2rem;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px;
  border: none;
  outline: none;
}

.FormBtn button {
  background-color: #f97304;
  color: white;
  border: none;
  border-radius: 100px;
  padding: 16px 45px 16px 45px;
  width: 100%;
  font-weight: 600;
  font-family: "Poppins", sans-serif;
}

.formLeftCol h2,
.formSection h4 {
  font-weight: 700;
  font-family: "DM Sans", sans-serif;
  color: #183b56;
  font-size: 40px;
}

.formLeftCol h6 {
  font-weight: 700;
  font-family: "Poppins", sans-serif;

  color: #5a7184;
  font-size: 20px;
}

.formSection h4 {
  font-size: 30px;
}

.formSection label {
  font-weight: 700;
  font-family: "Poppins", sans-serif;

  color: #292929;
}

.formLeftCol p:last-child {
  font-weight: 400;
  font-family: "Poppins", sans-serif;

  color: #5a7184;
  font-size: 13px;
  margin-left: 5px;
}

.ImgSection img {
  position: relative;
  left: 119px;
}

.ImgSection {
  b {
    color: #5a7184;
    font-weight: 700;
    font-size: 20px;

    @media screen and (max-width: 1200px) {
      font-size: 16px;
    }
  }

  p {
    padding-right: 100px;

    @media screen and (max-width: 1200px) {
      padding-right: 0px;
    }
  }
}

.ImgSection h2 {
  color: #f97304;
}

.formLeftCol input {
  border: 1px solid #e8ecfe;
  border-radius: 50px;
  color: #000;
  font-family: "Poppins", sans-serif;

  &::placeholder {
    color: #969696;
  }
}

.headerLine {
  border: 1px solid #ebebeb;
  display: block;
  margin: 0;
  margin: 0.5rem 0rem;
}
.formOrangeText {
  color: #f97304;
}
.personImg {
  position: relative;
  width: 350px;
}

@media screen and (max-width: 1100px) {
  .ImgSection img {
    left: 100px;
  }
}

.footer1 {
  @media (max-width: 767.98px) {
    flex-direction: column;
  }

  .footerLine {
    border: 1px solid #c2c2c2;
  }

  h6 {
    font-weight: 400;
    font-family: "Poppins", sans-serif;
    font-size: 16px;
    color: #000000;

    @media (max-width: 991.98px) {
      padding: 0.1rem 0;
    }
  }
}

.textUnderline {
  text-decoration: underline;
}

.home_card_one {
  background-color: white !important;
  border: none !important;
  border-radius: 12px;
}

.spinner {
  right: 10px;
  top: 7px;
}

.HeroLeftCol2 {
  h1 {
    color: #f97304;
    font-family: "DM Sans", sans-serif;
    font-weight: 900;
    font-size: 58px;
    line-height: 71px;

    @media (max-width: 1292.5px) {
      font-size: 47px;
    }
    @media (max-width: 1084.5px) {
      font-size: 42px;
    }
    @media (max-width: 991.5px) {
      font-size: 58px;
    }
    @media (max-width: 426px) {
      font-size: 45px;
    }

    @media (min-width: 375px) and(max-width:575px) {
      font-size: 34px;
      line-height: 49px;
    }

    @media (min-width: 576px) and(max-width:767px) {
      font-size: 44px;
      line-height: 54px;
    }
  }

  h1 span {
    color: #183b56;
  }

  p {
    font-family: "Poppins", sans-serif;
    font-weight: 500;
    font-size: 18px;
    line-height: 29px;

    i {
      color: #69bc3b;
    }

    span {
      color: #183b56;

      @media (min-width: 375px) and(max-width:575px) {
        font-size: 13px;
      }
    }
  }

  h6 {
    font-family: "Poppins", sans-serif;
    font-weight: 400;
    font-size: 18px;
    line-height: 32px;
    color: #183b56 !important;
  }

  .bottomSecCard {
    b {
      color: #000000;
    }
  }

  .heroCard2 {
    border: none;
    // padding: px;
    border-radius: 50px;
    width: 584px;
    flex-direction: row;

    @media (max-width: 992px) {
      width: 439px;
    }

    @media (max-width: 768px) {
      width: 439px;
    }

    @media (max-width: 576px) {
      width: 439px;
    }

    @media (min-width: 425px) and(max-width:575px) {
      width: 414px;
    }

    @media (min-width: 375px) and(max-width:425px) {
      width: 308px;
    }

    input {
      border-radius: 30px;
      width: 139px;
      color: #000000;
      border-color: #e8ecfe;
      margin-top: 11px;
      margin-left: 7px;

      @media (max-width: 992px) {
        width: 99px;
      }

      @media (max-width: 768px) {
        width: 99px;
      }

      @media (max-width: 576px) {
        width: 99px;
      }

      @media (max-width: 425px) {
        width: 99px;
      }

      @media (min-width: 375px) and(max-width:424px) {
        width: 68px;
      }
    }
  }
}

.background {
  overflow-x: hidden;
  overflow-y: hidden;
}

.heroSecRow {
  margin-left: 85px;
  margin-right: 85px;
  padding: 0%;

  @media (min-width: 375px) and(max-width:575.98px) {
    margin-left: 10px;
    margin-right: 10px;
  }
}

.greenText {
  color: #04c700;
}

.LoaderModalBg {
  background-color: #fef7e7 !important;
  align-items: center;
  border: #fef7e7;
  padding-top: 50px;
  padding-bottom: 50px;
  height: 497px;
  border-radius: 20px;

  .loaderModalCard {
    text-align: center;
    width: 58%;
    height: 342px;
    background-color: white;
    justify-content: center;
    margin-top: 20px;
    border-radius: 20px;
    border: #fef7e7;

    @media (max-width: 576px) {
      background-color: white;
    }
    @media (min-width: 375px) and (max-width: 575px) {
      width: 92%;
    }

    h2 {
      margin-bottom: 48px;
      font-family: "DM Sans", sans-serif;
      font-size: 32px;
      font-weight: 900;

      @media (max-width: 576px) {
        font-size: 27px;
      }
    }

    .spinner {
      width: 48px;
      height: 48px;
      font-weight: bolder;
    }

    .loader {
      text-align: center !important;
    }
  }
}
.ErrorResponse {
  margin-top: 10px;
  margin-left: 5px;
}

.LoaderModalBg3 {
  background-color: #fef7e7;
  align-items: center;
  border: #fef7e7;
  padding-top: 20px;
  padding-bottom: 20px;
  // height: 829px;
  border-radius: 20px;
  @media (min-width: 768px) and (max-width: 991.98px) {
    height: auto;
  }
  @media (min-width: 375px) and (max-width: 575.98px) {
    height: 774px;
  }

  .loaderModalCard {
    text-align: center;
    width: 58%;
    height: 342px;
    background-color: white;
    justify-content: center;
    margin-top: 20px;
    border-radius: 15px;
    border: #fef7e7;

    @media (max-width: 576px) {
      background-color: white;
    }
    @media (min-width: 375px) and (max-width: 575px) {
      width: 92%;
    }

    h2 {
      margin-bottom: 48px;
      font-family: "DM Sans", sans-serif;
      font-size: 32px;
      font-weight: 900;

      @media (max-width: 576px) {
        font-size: 27px;
      }
    }

    .spinner {
      width: 48px;
      height: 48px;
      font-weight: bolder;
    }

    .loader {
      text-align: center !important;
    }
  }
}

.loaderModalCard2 {
  text-align: center;
  width: 58%;
  height: 342px;
  background-color: white;
  justify-content: center;
  margin-top: 20px;
  border-radius: 20px;
  border: #fef7e7;
  align-items: center;

  @media (min-width: 375px) and (max-width: 575px) {
    width: 92%;
  }
  h3 {
    font-family: "DM Sans", sans-serif;
    font-size: 29px;
    font-weight: 900;

    @media (max-width: 767px) {
      font-size: 20px;
    }
  }

  svg {
    margin-bottom: 20px;
    align-items: center;
  }

  .spinner {
    width: 48px;
    height: 48px;
    font-weight: bolder;
  }

  .loader {
    text-align: center !important;
  }
}

.formSection2 h4 {
  font-weight: 700;
  font-family: "DM Sans", sans-serif;
  color: #183b56;
  font-size: 40px;
}

.formLeftCol2 h2 {
  font-weight: 700;
  font-family: "DM Sans", sans-serif;
  color: #183b56;
  font-size: 40px;
  font-weight: 700;
  font-family: "DM Sans", sans-serif;
  color: #183b56;
  font-size: 40px;
}

.formLeftCol2 h6 {
  font-weight: 700;
  font-family: "Poppins", sans-serif;

  color: #5a7184;
  font-size: 20px;
}

.formSection2 h4 {
  font-size: 25px;
  font-family: "DM Sans", sans-serif;

  @media (min-width: 992px) and(max-width: 1200px) {
    font-size: 22px;
  }
}

.formSection2 label {
  font-weight: 700;
  font-family: "Poppins", sans-serif;

  color: #292929;
}

.formLeftCol2 p:last-child {
  font-weight: 600;
  font-family: "Poppins", sans-serif;

  color: #5a7184;
  font-size: 10px;
  margin-left: 5px;

  @media (min-width: 992px) and(max-width: 1200px) {
    font-size: 8px;
  }
}

.formSection2 {
  background-color: white !important;
  border-radius: 20px;
  margin: 2rem 0.5rem;
  padding: 1rem 2rem;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px;
  border: none;
  outline: none;
  margin-left: 45px;

  @media (min-width: 576px) and(max-width:992px) {
    margin: 60px;
  }
  @media (min-width: 375px) and(max-width:575.98px) {
    margin: 20px;
  }
}

.formLeftCol2 input {
  border: 1px solid #e8ecfe;
  border-radius: 50px;
  color: #000;
  font-family: "Poppins", sans-serif;

  &::placeholder {
    color: #969696;
  }
}

.formImgCard {
  // padding: 30px;
  border-radius: 15px;
  border: #fef7e7;
  text-align: center;
  width: 68%;
  height: 37%;
  left: 50px;
  bottom: 20px;
  overflow: hidden;

  h4 {
    font-weight: 600;
    padding: 24px;
    b {
      color: #5a7184;
    }
  }
}

.ImgSection2 {
  // @media (max-width: 991.98px) {
  //   margin-left: 110px;
  // }
  .svg-img-div {
    svg {
      position: absolute;
      bottom: 172px;
      left: 35%;
      @media (max-width: 1292px) {
        width: 150px;
        height: 150px;
      }
      @media (max-width: 1400px) {
        left: 30%;
      }
      @media (max-width: 1360px) {
        bottom: 152px;
        width: 140px;
        height: 140px;
      }
      @media (max-width: 1050px) {
        width: 130px;
        height: 130px;
      }
      @media (max-width: 991.5px) {
        bottom: 270px;
        width: 190px;
        height: 190px;
        left: 46%;
      }
      @media (max-width: 850.5px) {
        bottom: 230px;
      }
    }
  }
  .groupImg {
    width: 90%;
    margin-top: 20px;
    @media (max-width: 1300px) {
      width: 100%;
    }
  }

  .formImg {
    bottom: 254px;
    position: relative;
  }
  .formImg1 {
    position: relative;
    width: 570px;

    @media (max-width: 991.98px) {
      margin-left: 12px;
    }
  }
  .formImg2 {
    bottom: 457px;
    position: relative;
    width: 570px;
    @media (max-width: 1298px) {
      bottom: 395px;
    }
    h4 {
      b {
        font-family: "DM Sans", sans-serif;
        font-size: 22px;
      }
    }

    @media (min-width: 768px) and(max-width: 991.98px) {
      left: 10px;
    }
  }
  .personImg {
    position: relative;
    width: 287px;
    left: 95px;
    bottom: 20px;
  }
  .frameImg {
    z-index: 3;
    position: relative;
    left: 259px;
    bottom: 154px;
    width: 132px;
    @media (max-width: 1298px) {
      left: 209px;
    }
  }
  // @media (max-width: 991.98px) {
  //   margin-left: 12px;
  // }

  // @media (min-width: 992px) and(max-width: 1050px) {
  //   bottom: 319px;
  // }

  // @media (min-width: 1051px) and(max-width: 1158px) {
  //   bottom: 360px;
  // }

  // @media (min-width: 1159px) and(max-width: 1200px) {
  //   bottom: 400px;
  // }
}

.ImgSection2 {
  b {
    color: #000000;
    font-weight: 700;
    font-size: 20px;

    @media screen and (max-width: 1200px) {
      font-size: 16px;
    }
  }

  p {
    padding-right: 100px;

    @media screen and (max-width: 1200px) {
      padding-right: 0px;
    }
  }
}

.ImgSection2 h2 {
  color: #f97304;
}

.checkIconText {
  p {
    @media (max-width: 1140px) {
      font-size: 14px;
    }
  }
}

.header-fileds .invalid-feedback {
  position: absolute;
}

.GreenTickIcon {
  width: 25px;
  height: 25px;
}

.thankYouBg {
  background-color: #fef7e7 !important;
  align-items: center;
  border: #fef7e7;
  padding-top: 50px;
  padding-bottom: 50px;
  height: 497px;
  border-radius: 20px;
  .thankYouCard {
    text-align: center;
    width: 80%;
    height: 342px;
    background-color: white;
    justify-content: center;
    margin-top: 20px;
    border-radius: 15px;
    border: #fef7e7;
    font-family: "DM Sans", sans-serif;
    padding: 50px;
    @media (min-width: 576px) and(max-width:767.98px) {
      width: 90%;
      padding: 35px;
    }
    @media (min-width: 375px) and(max-width:575.98px) {
      width: 90%;
      padding: 30px;
    }
    h3 {
      font-weight: 700;
      font-family: "DM Sans", sans-serif;
    }
  }
}

.form_footer_pfb {
  p {
    font-weight: 400 !important;
  }
}
